<template>
  <div class="actionFrom">
    <a-spin class="isLoading" :spinning="formLoading" />
    <Theme
      :distinguish = "distinguish"
      :form="form"
      :config="form.config"
      :lookForm="true"
      @submit="saveData"
      v-if="!formLoading"
    />
  </div>
</template>

<script>
import { getForm, addFormData } from '@/services/form'
import Theme from './components/Theme.vue'
export default {
  components: { Theme },
  data() {
    return {
      distinguish: '',  //区分是查询表单(1)还是编辑表单(2)
      form: [],
      formLoading: false,
      company_uuid: '',
      uuid: ''
    }
  },
  created() {
    this.distinguish = '2'
    const { company_uuid, uuid } = this.$route.params
    this.company_uuid = company_uuid
    localStorage.setItem('company_uuid', company_uuid)
    this.uuid = uuid
    this.init()
  },
  methods: {
    init() {
      this.formLoading = true
      getForm({ company_uuid: this.company_uuid, uuid: this.uuid })
        .then((res) => {
          let { data } = res
          this.form = data
          this.formLoading = false
          document.title = data.config.title
        })
        .catch(() => {
          this.$message.error('地址错误！')
        })
    },
    saveData(data) {
      console.log('保存传递的参数', data)
      addFormData(
        {
          company_uuid: this.company_uuid,
          uuid: this.uuid
        },
        data
      )
        .then((res) => {
          const { code } = res.data
          if (code === 200) {
            this.$message.success('添加成功！')
            setTimeout(() => {
              this.$router.go(0)
            }, 500)
          } else {
            this.$message.error('添加失败！')
          }
        })
        .catch(() => {
          this.$message.error('系统内容错误！')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.actionFrom {
  width: 100%;
  height: 100%;

  .content {
    min-height: 100vh;
  }
}
</style>
